import React from 'react';

const BrandingIcon = () => {
    return (
        <div className='w-10'>
            <svg width="100%" height="100%" viewBox="0 0 267 267">
                <defs>
                    <linearGradient id="linear-gradient" x2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#3376bd" stop-opacity="0.212" />
                        <stop offset="0.148" stop-color="#3e76bc" stop-opacity="0.212" />
                        <stop offset="0.455" stop-color="#d977af" stop-opacity="0.212" />
                        <stop offset="0.545" stop-color="#ec7ba3" stop-opacity="0.212" />
                        <stop offset="0.636" stop-color="#f98196" stop-opacity="0.212" />
                        <stop offset="0.727" stop-color="#ff8b88" stop-opacity="0.212" />
                        <stop offset="0.818" stop-color="#ff977c" stop-opacity="0.212" />
                        <stop offset="0.909" stop-color="#ffa672" stop-opacity="0.212" />
                        <stop offset="1" stop-color="#ffb56b" stop-opacity="0.212" />
                    </linearGradient>
                </defs>
                <g id="Group_18" data-name="Group 18" transform="translate(-12639 -1259)">
                    <circle id="Ellipse_14" className='iconTrans' data-name="Ellipse 14" cx="133.5" cy="133.5" r="133.5" transform="translate(12639 1259)" fill="url(#linear-gradient)" />
                    <path id="Icon_open-pencil" data-name="Icon open-pencil" d="M80.511,0,67.092,13.418,93.929,40.255l13.418-13.418ZM53.674,26.837,0,80.511v26.837H26.837L80.511,53.674Z" transform="translate(12718.826 1338.826)" fill="#f3a772" />
                </g>
            </svg>

        </div>
    )
}

export default BrandingIcon