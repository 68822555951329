import React from 'react';

const OnboardIcon = () => {
    return (
        <div className='w-10'>
            <svg width="100%" height="100%" viewBox="0 0 267 267">
                <defs>
                    <linearGradient id="linear-gradient" x2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#3376bd" stop-opacity="0.212" />
                        <stop offset="0.148" stop-color="#3e76bc" stop-opacity="0.212" />
                        <stop offset="0.455" stop-color="#d977af" stop-opacity="0.212" />
                        <stop offset="0.545" stop-color="#ec7ba3" stop-opacity="0.212" />
                        <stop offset="0.636" stop-color="#f98196" stop-opacity="0.212" />
                        <stop offset="0.727" stop-color="#ff8b88" stop-opacity="0.212" />
                        <stop offset="0.818" stop-color="#ff977c" stop-opacity="0.212" />
                        <stop offset="0.909" stop-color="#ffa672" stop-opacity="0.212" />
                        <stop offset="1" stop-color="#ffb56b" stop-opacity="0.212" />
                    </linearGradient>
                </defs>
                <g id="Group_15" data-name="Group 15" transform="translate(-11942 -1619)">
                    <circle id="Ellipse_10" className='iconTrans' data-name="Ellipse 10" cx="133.5" cy="133.5" r="133.5" transform="translate(11942 1619)" fill="url(#linear-gradient)" />
                    <path id="Icon_awesome-hands-helping" data-name="Icon awesome-hands-helping" d="M100.788,40.256H69.4V52a14.872,14.872,0,1,1-29.74,0V26.5l-13.4,8.178A13.434,13.434,0,0,0,19.83,46.169v9.918L3.308,65.774A6.75,6.75,0,0,0,.891,74.937L17.413,104a6.557,6.557,0,0,0,9.025,2.453L47.793,93.934H76a13.332,13.332,0,0,0,13.218-13.42h3.3a6.653,6.653,0,0,0,6.609-6.71V60.385h1.652a4.983,4.983,0,0,0,4.957-5.032V45.288A4.983,4.983,0,0,0,100.788,40.256Zm30.5-7.842L114.769,3.352A6.557,6.557,0,0,0,105.744.9L84.389,13.417H63.283a13.169,13.169,0,0,0-7,2.034l-6.919,4.382a6.689,6.689,0,0,0-3.1,5.682V52a8.262,8.262,0,1,0,16.522,0V33.546h38a11.662,11.662,0,0,1,11.565,11.742v5.976l16.522-9.687a6.773,6.773,0,0,0,2.416-9.163Z" transform="translate(12009.414 1698.825)" fill="#f07d9e" />
                </g>
            </svg>


        </div>
    )
}

export default OnboardIcon