import React from 'react';

const Logo = () => {
    return (
        <div>
            <svg width="100%" height="100%" viewBox="0 0 155.863 48.852">
                <defs>
                    <linearGradient id="linear-gradient" x2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#3376bd" />
                        <stop offset="0.148" stopColor="#3e76bc" />
                        <stop offset="0.455" stopColor="#d977af" />
                        <stop offset="0.545" stopColor="#ec7ba3" />
                        <stop offset="0.636" stopColor="#f98196" />
                        <stop offset="0.727" stopColor="#ff8b88" />
                        <stop offset="0.818" stopColor="#ff977c" />
                        <stop offset="0.909" stopColor="#ffa672" />
                        <stop offset="1" stopColor="#ffb56b" />
                    </linearGradient>
                </defs>
                <g id="Group_6" data-name="Group 6" transform="translate(-30.455 -267.045)">
                    <g id="Group_1" data-name="Group 1" transform="translate(87.855 267.807)">
                        <path id="Path_1" data-name="Path 1" d="M281.384,337.188v-20.35a3.375,3.375,0,0,1,3.375-3.375h0a3.375,3.375,0,0,1,3.375,3.375v.275a8.042,8.042,0,0,1,7.5-4.4h.033a3.111,3.111,0,0,1,3.016,3.42l-.047.53a3.141,3.141,0,0,1-3.254,2.856q-.15-.006-.3-.006c-4,0-6.65,2.6-6.65,8.25v9.425a3.525,3.525,0,0,1-3.525,3.525h0A3.525,3.525,0,0,1,281.384,337.188Z" transform="translate(-281.384 -302.712)" fill="#001730" />
                        <path id="Path_2" data-name="Path 2" d="M381.074,329.734H363.521c1,3.85,3.65,5.8,7.951,5.8a16.69,16.69,0,0,0,6.484-1.479,2.92,2.92,0,0,1,3.73,1.229h0a2.91,2.91,0,0,1-1.153,4.017,20.061,20.061,0,0,1-9.361,2.183c-4.8,0-8.45-1.4-11-4.15a14.341,14.341,0,0,1-3.8-10.1,13.99,13.99,0,0,1,3.949-10.251,14.915,14.915,0,0,1,20.051-.349c2.55,2.45,3.85,5.75,3.85,10v.029A3.124,3.124,0,0,1,381.074,329.734Zm-16.588-5.25H376.46a.722.722,0,0,0,.69-.927,6.479,6.479,0,0,0-6.578-4.773,6.887,6.887,0,0,0-6.764,4.739A.721.721,0,0,0,364.486,324.484Z" transform="translate(-339.334 -302.884)" fill="#001730" />
                        <path id="Path_3" data-name="Path 3" d="M496.5,306.7h0a3.525,3.525,0,0,1-3.525-3.525v-30.95A3.525,3.525,0,0,1,496.5,268.7h0a3.525,3.525,0,0,1,3.525,3.525v30.95A3.525,3.525,0,0,1,496.5,306.7Z" transform="translate(-444.902 -268.701)" fill="#001730" />
                        <path id="Path_4" data-name="Path 4" d="M542.171,274.705a3.609,3.609,0,0,1,0-5,3.66,3.66,0,0,1,5.039,0,3.609,3.609,0,0,1,0,5A3.658,3.658,0,0,1,542.171,274.705Zm2.52,32h0a3.525,3.525,0,0,1-3.525-3.525v-20.2a3.525,3.525,0,0,1,3.525-3.525h0a3.525,3.525,0,0,1,3.525,3.525v20.2A3.525,3.525,0,0,1,544.691,306.7Z" transform="translate(-482.141 -268.701)" fill="#001730" />
                        <path id="Path_5" data-name="Path 5" d="M590.323,316.913a15.222,15.222,0,0,1,21,0,14.675,14.675,0,0,1,0,20.35,15.359,15.359,0,0,1-21,0,14.675,14.675,0,0,1,0-20.35Zm10.5,17.95a7.1,7.1,0,0,0,5.35-2.25,7.868,7.868,0,0,0,2.2-5.55,7.664,7.664,0,0,0-2.2-5.5,7.1,7.1,0,0,0-5.35-2.25,7.206,7.206,0,0,0-5.4,2.25,7.621,7.621,0,0,0-2.15,5.5,7.823,7.823,0,0,0,2.15,5.55A7.209,7.209,0,0,0,600.823,334.863Z" transform="translate(-516.96 -302.712)" fill="#001730" />
                    </g>
                    <g id="Group_2" data-name="Group 2" transform="translate(30.955 267.545)">
                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="2.829" cy="2.829" r="2.829" transform="translate(5.372 5.086)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                        <circle id="Ellipse_2" data-name="Ellipse 2" cx="2.829" cy="2.829" r="2.829" transform="translate(37.174 4.952)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                        <circle id="Ellipse_3" data-name="Ellipse 3" cx="2.829" cy="2.829" r="2.829" transform="translate(37.067 36.821)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                        <circle id="Ellipse_4" data-name="Ellipse 4" cx="2.829" cy="2.829" r="2.829" transform="translate(5.358 36.807)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                        <path id="Path_6" data-name="Path 6" d="M97.159,321.8H91.012a6.117,6.117,0,0,0-5.3,3.059l-3.073,5.323a6.117,6.117,0,0,0,0,6.117l3.073,5.323a6.117,6.117,0,0,0,5.3,3.059h6.146a6.118,6.118,0,0,0,5.3-3.059l3.073-5.323a6.117,6.117,0,0,0,0-6.117l-3.073-5.323A6.118,6.118,0,0,0,97.159,321.8Z" transform="translate(-70.264 -309.476)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                        <path id="Path_7" data-name="Path 7" d="M47.975,289.852a20.71,20.71,0,0,1-6.436-1.22.909.909,0,0,1,.639-1.7h0c1.928.721,7.4,2.116,9.555-.067,1.472-1.493,1.32-4.664-.439-9.17a.909.909,0,1,1,1.693-.661c2.07,5.3,2.083,9.038.039,11.109A6.93,6.93,0,0,1,47.975,289.852Z" transform="translate(-38.679 -274.43)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                        <path id="Path_8" data-name="Path 8" d="M179.83,289.609a5.873,5.873,0,0,1-4.308-1.523c-3.466-3.422-.613-11.158-.49-11.486a.909.909,0,0,1,1.7.639h0c-.722,1.928-2.117,7.4.067,9.555,1.493,1.472,4.663,1.32,9.169-.439a.909.909,0,0,1,.661,1.693A19.37,19.37,0,0,1,179.83,289.609Z" transform="translate(-141.353 -274.087)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                        <path id="Path_9" data-name="Path 9" d="M173.406,421.844a.909.909,0,0,1-.847-.578c-2.07-5.3-2.083-9.038-.039-11.109,3.422-3.466,11.159-.613,11.486-.49a.909.909,0,0,1-.64,1.7c-1.928-.722-7.4-2.116-9.554.067-1.472,1.493-1.32,4.664.439,9.169a.909.909,0,0,1-.846,1.24Z" transform="translate(-139.178 -376.432)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                        <circle id="Ellipse_5" data-name="Ellipse 5" cx="2.829" cy="2.829" r="2.829" transform="translate(42.153 20.698)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                        <path id="Path_10" data-name="Path 10" d="M204.343,351.874a.906.906,0,0,1-.364-.077c-5.212-2.285-7.864-4.918-7.883-7.828-.031-4.87,7.456-8.324,7.775-8.468a.909.909,0,0,1,.751,1.655c-1.874.853-6.73,3.737-6.708,6.8.015,2.1,2.364,4.231,6.795,6.173a.909.909,0,0,1-.365,1.742Z" transform="translate(-158.574 -319.998)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                        <circle id="Ellipse_6" data-name="Ellipse 6" cx="2.829" cy="2.829" r="2.829" transform="translate(20.87 0)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                        <path id="Path_11" data-name="Path 11" d="M108.107,281.69c-4.854,0-8.294-7.457-8.438-7.775a.909.909,0,1,1,1.655-.751c.851,1.87,3.725,6.709,6.783,6.709h.02c2.1-.015,4.231-2.364,6.173-6.795a.909.909,0,0,1,1.665.73c-2.285,5.212-4.918,7.864-7.828,7.883Z" transform="translate(-83.993 -271.4)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                        <circle id="Ellipse_7" data-name="Ellipse 7" cx="2.829" cy="2.829" r="2.829" transform="translate(0 20.961)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                        <path id="Path_12" data-name="Path 12" d="M36.95,351.956a.909.909,0,0,1-.376-1.737c1.874-.853,6.73-3.737,6.708-6.8-.015-2.1-2.364-4.231-6.795-6.173a.909.909,0,0,1,.73-1.665c5.212,2.285,7.864,4.918,7.883,7.828.031,4.87-7.456,8.324-7.775,8.468A.9.9,0,0,1,36.95,351.956Z" transform="translate(-34.81 -320.061)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                        <circle id="Ellipse_8" data-name="Ellipse 8" cx="2.829" cy="2.829" r="2.829" transform="translate(20.992 42.193)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                        <path id="Path_13" data-name="Path 13" d="M99.961,442.017a.909.909,0,0,1-.832-1.274c2.284-5.212,4.918-7.864,7.828-7.883h.03c4.854,0,8.293,7.457,8.438,7.775a.909.909,0,1,1-1.655.751c-.851-1.869-3.724-6.709-6.783-6.709h-.02c-2.1.015-4.231,2.364-6.173,6.795A.909.909,0,0,1,99.961,442.017Z" transform="translate(-83.58 -395.299)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                        <path id="Path_14" data-name="Path 14" d="M52.72,421.606a.909.909,0,0,1-.851-1.228h0c.722-1.928,2.116-7.4-.067-9.555-1.493-1.472-4.664-1.32-9.169.44a.909.909,0,0,1-.661-1.693c5.3-2.07,9.038-2.083,11.109-.039,3.466,3.422.613,11.158.49,11.486A.909.909,0,0,1,52.72,421.606Z" transform="translate(-39.022 -376.092)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                    </g>
                </g>
            </svg>

        </div>

    )
}

export default Logo;