import React, { Component } from 'react';

class LogoFooter extends Component {
   
    render() {
        return (<div>
            <svg  width="100%" height="100%" viewBox="0 0 113.638 113.732">
                <defs>
                    <linearGradient id="linear-gradient" x2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#3376bd" />
                        <stop offset="0.148" stopColor="#3e76bc" />
                        <stop offset="0.455" stopColor="#d977af" />
                        <stop offset="0.545" stopColor="#ec7ba3" />
                        <stop offset="0.636" stopColor="#f98196" />
                        <stop offset="0.727" stopColor="#ff8b88" />
                        <stop offset="0.818" stopColor="#ff977c" />
                        <stop offset="0.909" stopColor="#ffa672" />
                        <stop offset="1" stopColor="#ffb56b" />
                    </linearGradient>
                </defs>
                <g id="Group_7" data-name="Group 7" transform="translate(0.5 0.5)" >
                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="6.665" cy="6.665" r="6.665" transform="translate(12.656 11.982)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                    <circle id="Ellipse_2" data-name="Ellipse 2" cx="6.665" cy="6.665" r="6.665" transform="translate(87.576 11.666)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                    <circle id="Ellipse_3" data-name="Ellipse 3" cx="6.665" cy="6.665" r="6.665" transform="translate(87.325 86.745)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                    <circle id="Ellipse_4" data-name="Ellipse 4" cx="6.665" cy="6.665" r="6.665" transform="translate(12.624 86.712)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                    <path id="Path_6" data-name="Path 6" d="M117.954,321.8h-14.48a14.411,14.411,0,0,0-12.481,7.206l-7.24,12.54a14.411,14.411,0,0,0,0,14.412l7.24,12.54a14.411,14.411,0,0,0,12.481,7.206h14.48a14.413,14.413,0,0,0,12.482-7.206l7.24-12.54a14.411,14.411,0,0,0,0-14.412l-7.24-12.54A14.413,14.413,0,0,0,117.954,321.8Z" transform="translate(-54.594 -292.76)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                    <path id="Path_7" data-name="Path 7" d="M57.5,308.018a48.791,48.791,0,0,1-15.162-2.875,2.141,2.141,0,0,1,1.5-4.009h0c4.542,1.7,17.438,4.986,22.51-.157,3.468-3.517,3.109-10.987-1.035-21.6a2.141,2.141,0,1,1,3.988-1.558c4.876,12.488,4.907,21.293.092,26.171C66.375,307.049,61.924,308.018,57.5,308.018Z" transform="translate(-35.6 -271.685)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                    <path id="Path_8" data-name="Path 8" d="M187.991,308.046c-4.333,0-7.726-1.2-10.149-3.589-8.165-8.061-1.445-26.288-1.155-27.059a2.141,2.141,0,0,1,4.009,1.5h0c-1.7,4.541-4.987,17.438.157,22.509,3.516,3.468,10.986,3.11,21.6-1.035a2.141,2.141,0,0,1,1.558,3.989C197.73,306.82,192.378,308.046,187.991,308.046Z" transform="translate(-97.343 -271.479)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                    <path id="Path_9" data-name="Path 9" d="M176.673,440.01a2.141,2.141,0,0,1-1.995-1.363c-4.876-12.488-4.907-21.293-.092-26.171,8.062-8.165,26.288-1.445,27.06-1.155a2.141,2.141,0,0,1-1.507,4.009c-4.541-1.7-17.436-4.986-22.508.158-3.468,3.516-3.109,10.987,1.035,21.6a2.142,2.142,0,0,1-1.993,2.92Z" transform="translate(-96.036 -333.025)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                    <circle id="Ellipse_5" data-name="Ellipse 5" cx="6.665" cy="6.665" r="6.665" transform="translate(99.307 48.761)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                    <path id="Path_10" data-name="Path 10" d="M215.525,374.184a2.133,2.133,0,0,1-.859-.18c-12.278-5.383-18.527-11.587-18.57-18.441-.074-11.473,17.566-19.609,18.317-19.95a2.141,2.141,0,0,1,1.769,3.9c-4.414,2.009-15.855,8.8-15.8,16.027.034,4.939,5.57,9.967,16.007,14.543a2.142,2.142,0,0,1-.86,4.1Z" transform="translate(-107.699 -299.088)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                    <circle id="Ellipse_6" data-name="Ellipse 6" cx="6.665" cy="6.665" r="6.665" transform="translate(49.167 0)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                    <path id="Path_11" data-name="Path 11" d="M119.659,294.106c-11.436,0-19.539-17.568-19.879-18.318a2.141,2.141,0,1,1,3.9-1.77c2.005,4.4,8.775,15.805,15.98,15.805h.048c4.939-.035,9.967-5.57,14.543-16.008a2.141,2.141,0,0,1,3.921,1.719c-5.382,12.278-11.587,18.527-18.441,18.57Z" transform="translate(-62.85 -269.863)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                    <circle id="Ellipse_7" data-name="Ellipse 7" cx="6.665" cy="6.665" r="6.665" transform="translate(0 49.38)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                    <path id="Path_12" data-name="Path 12" d="M38.314,374.266a2.141,2.141,0,0,1-.885-4.091c4.414-2.009,15.855-8.8,15.8-16.027-.034-4.939-5.57-9.967-16.007-14.542a2.141,2.141,0,0,1,1.719-3.922c12.278,5.383,18.527,11.587,18.57,18.441.074,11.473-17.566,19.609-18.318,19.95A2.13,2.13,0,0,1,38.314,374.266Z" transform="translate(-33.273 -299.126)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                    <circle id="Ellipse_8" data-name="Ellipse 8" cx="6.665" cy="6.665" r="6.665" transform="translate(49.455 99.401)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                    <path id="Path_13" data-name="Path 13" d="M101.193,454.432a2.141,2.141,0,0,1-1.96-3c5.382-12.278,11.586-18.526,18.441-18.57h.072c11.435,0,19.538,17.568,19.878,18.318a2.141,2.141,0,1,1-3.9,1.769c-2-4.4-8.774-15.8-15.979-15.8H117.7c-4.938.035-9.967,5.57-14.542,16.008A2.14,2.14,0,0,1,101.193,454.432Z" transform="translate(-62.601 -344.37)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                    <path id="Path_14" data-name="Path 14" d="M68.078,440.044a2.142,2.142,0,0,1-2-2.894h0c1.7-4.541,4.986-17.438-.157-22.51-3.517-3.468-10.987-3.11-21.6,1.036a2.141,2.141,0,0,1-1.558-3.989c12.488-4.876,21.293-4.907,26.171-.091,8.165,8.061,1.445,26.287,1.155,27.06A2.142,2.142,0,0,1,68.078,440.044Z" transform="translate(-35.806 -332.82)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linear-gradient)" />
                </g>
            </svg>

        </div>);
    }
}

export default LogoFooter;