import React from 'react';

const ColleaguesIcon = () => {
    return (
        <div className='w-10'>
            <svg width="100%" height="100%" viewBox="0 0 267 267">
                <defs>
                    <linearGradient id="linear-gradient" x2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#3376bd" stop-opacity="0.212" />
                        <stop offset="0.148" stop-color="#3e76bc" stop-opacity="0.212" />
                        <stop offset="0.455" stop-color="#d977af" stop-opacity="0.212" />
                        <stop offset="0.545" stop-color="#ec7ba3" stop-opacity="0.212" />
                        <stop offset="0.636" stop-color="#f98196" stop-opacity="0.212" />
                        <stop offset="0.727" stop-color="#ff8b88" stop-opacity="0.212" />
                        <stop offset="0.818" stop-color="#ff977c" stop-opacity="0.212" />
                        <stop offset="0.909" stop-color="#ffa672" stop-opacity="0.212" />
                        <stop offset="1" stop-color="#ffb56b" stop-opacity="0.212" />
                    </linearGradient>
                </defs>
                <g id="Group_17" data-name="Group 17" transform="translate(-12639 -899)">
                    <circle id="Ellipse_9" className='iconTrans' data-name="Ellipse 9" cx="133.5" cy="133.5" r="133.5" transform="translate(12639 899)" fill="url(#linear-gradient)" />
                    <g id="Icon_ionic-ios-people" data-name="Icon ionic-ios-people" transform="translate(12687.695 971.078)">
                        <path id="Path_48" data-name="Path 48" d="M93.761,90.338c-4.12-1.472-10.853-1.545-13.832-2.686-2.06-.809-5.334-1.692-6.4-2.98S72.46,74.188,72.46,74.188a16.937,16.937,0,0,0,3.642-5.15c1.067-2.686,1.766-10.117,1.766-10.117s2.428,1.03,3.385-3.826c.809-4.194,2.354-6.4,1.95-9.491-.441-3.09-2.134-2.354-2.134-2.354S83.2,40.122,83.2,29.49c0-10.963-8.277-21.742-23.765-21.742-15.451,0-23.8,10.816-23.8,21.742,0,10.632,2.1,13.759,2.1,13.759S36,42.513,35.6,45.6c-.441,3.09,1.1,5.3,1.95,9.491.956,4.893,3.385,3.826,3.385,3.826s.7,7.394,1.766,10.117a16.937,16.937,0,0,0,3.642,5.15s0,9.2-1.067,10.485-4.341,2.17-6.4,2.943C35.93,88.756,29.2,88.9,25.04,90.375A25.963,25.963,0,0,0,8.191,115.1H110.573A25.912,25.912,0,0,0,93.761,90.338Z" transform="translate(25.293 0)" fill="#f1967c" />
                        <path id="Path_49" data-name="Path 49" d="M42.13,57.782s8.792-.294,12.287-3.421c-5.7-8.645-2.612-18.725-3.789-28.143-1.177-9.381-6.512-15.01-17.18-15.01H33.3c-10.3,0-15.856,5.592-17.033,15.01-1.177,9.381,2.1,20.6-3.752,28.143,3.495,3.164,12.324,3.053,12.324,3.053h0s.368,5.3-.368,6.18-2.906,1.729-4.415,2.023a85.355,85.355,0,0,0-9.528,2.649c-2.869.993-8.314,6.328-8.314,13.685H31.756c.809-2.943,6.364-8.2,11.772-10.963,3.311-1.692,6.585-1.582,9.087-1.913,0,0,1.4-2.207-3.2-3.053,0,0-6.328-1.582-7.063-2.465C41.652,62.748,42.13,57.782,42.13,57.782Z" transform="translate(0 14.64)" fill="#f1967c" />
                        <path id="Path_50" data-name="Path 50" d="M36.1,57.782S27.3,57.487,23.808,54.36c5.7-8.645,2.612-18.725,3.789-28.143,1.177-9.381,6.512-15.01,17.18-15.01h.147c10.3,0,15.856,5.592,17.033,15.01,1.177,9.381-2.1,20.6,3.752,28.143-3.495,3.164-12.324,3.053-12.324,3.053h0s-.368,5.3.368,6.18,2.906,1.729,4.415,2.023A85.355,85.355,0,0,1,67.7,68.266c2.869.993,8.314,6.328,8.314,13.685H46.469c-.809-2.943-6.364-8.2-11.772-10.963-3.311-1.692-6.585-1.582-9.087-1.913,0,0-1.4-2.207,3.2-3.053,0,0,6.328-1.582,7.063-2.465C36.573,62.748,36.1,57.782,36.1,57.782Z" transform="translate(91.383 14.64)" fill="#f1967c" />
                    </g>
                </g>
            </svg>


        </div>
    )
}

export default ColleaguesIcon