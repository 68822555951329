import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FooterLink, H4, Row } from '../general';

const FooterLinkCollection = () => {
    const { t } = useTranslation();

    return (
        <Row className='justify-between my-5 md:grid md:grid-cols-3'>
            <Col className='flex flex-col gap-1 md:self-start'>
                <H4 text={t('nav.productTitle')} />
                <FooterLink text={t('nav.inform')} />
                <FooterLink text={t('nav.scheduling')} />
                <FooterLink text={t('nav.onboard')} />
                <FooterLink text={t('nav.talk')} />
                <FooterLink text={t('nav.colleagues')} />
                <FooterLink text={t('nav.branding')} />
                <FooterLink text={t('nav.pricingTitle')} link='pricing' />
                <FooterLink text={t('nav.settings')} />

            </Col>

            <Col className='flex flex-col gap-1 md:items-center md:self-start'>
                <H4 text={t('nav.learnTitle')} />
                <FooterLink text={t('nav.inform')} />
                <FooterLink text={t('nav.scheduling')} />
                <FooterLink text={t('nav.onboard')} />
                <FooterLink text={t('nav.talk')} />
                <FooterLink text={t('nav.colleagues')} />
                <FooterLink text={t('nav.branding')} />
                <FooterLink text={t('nav.pricingTitle')} link='pricing' />
                <FooterLink text={t('nav.settings')} />
            </Col>

            <Col className='flex flex-col gap-1 md:items-end md:self-start'>
                <H4 text={t('nav.companyTitle')} />
                <FooterLink text={t('nav.about')}/>
                <FooterLink text={t('nav.contact')} link="contact" />
                <FooterLink text={t('nav.aff')} />
            </Col>
        </Row>);
}

export default FooterLinkCollection