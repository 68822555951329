import React from 'react';

const SettingsIcon = () => {
    return (
        <div className='w-10'>
            <svg width="100%" height="100%" viewBox="0 0 267 267">
                <defs>
                    <linearGradient id="linear-gradient" x2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#3376bd" stop-opacity="0.212" />
                        <stop offset="0.148" stop-color="#3e76bc" stop-opacity="0.212" />
                        <stop offset="0.455" stop-color="#d977af" stop-opacity="0.212" />
                        <stop offset="0.545" stop-color="#ec7ba3" stop-opacity="0.212" />
                        <stop offset="0.636" stop-color="#f98196" stop-opacity="0.212" />
                        <stop offset="0.727" stop-color="#ff8b88" stop-opacity="0.212" />
                        <stop offset="0.818" stop-color="#ff977c" stop-opacity="0.212" />
                        <stop offset="0.909" stop-color="#ffa672" stop-opacity="0.212" />
                        <stop offset="1" stop-color="#ffb56b" stop-opacity="0.212" />
                    </linearGradient>
                </defs>
                <g id="Group_19" data-name="Group 19" transform="translate(-12639 -1619)">
                    <circle id="Ellipse_12" className='iconTrans' data-name="Ellipse 12" cx="133.5" cy="133.5" r="133.5" transform="translate(12639 1619)" fill="url(#linear-gradient)" />
                    <path id="Icon_ionic-ios-settings" data-name="Icon ionic-ios-settings" d="M102.96,58.16a13.81,13.81,0,0,1,8.859-12.884A54.74,54.74,0,0,0,105.2,29.318a14,14,0,0,1-5.618,1.2,13.779,13.779,0,0,1-12.6-19.4A54.574,54.574,0,0,0,71.044,4.5,13.822,13.822,0,0,1,58.16,13.359,13.822,13.822,0,0,1,45.276,4.5a54.74,54.74,0,0,0-15.958,6.624,13.779,13.779,0,0,1-12.6,19.4,13.54,13.54,0,0,1-5.618-1.2A55.95,55.95,0,0,0,4.5,45.3a13.794,13.794,0,0,1,8.859,12.884A13.826,13.826,0,0,1,4.528,71.072,54.74,54.74,0,0,0,11.152,87.03a13.785,13.785,0,0,1,18.194,18.194A55.059,55.059,0,0,0,45.3,111.848a13.773,13.773,0,0,1,25.712,0,54.74,54.74,0,0,0,15.958-6.624A13.8,13.8,0,0,1,105.168,87.03a55.059,55.059,0,0,0,6.624-15.958A13.876,13.876,0,0,1,102.96,58.16ZM58.411,80.49A22.358,22.358,0,1,1,80.77,58.132,22.352,22.352,0,0,1,58.411,80.49Z" transform="translate(12714.34 1694.326)" fill="#f4b46b" />
                </g>
            </svg>
        </div>
    )
}

export default SettingsIcon