import React, { useEffect, useState } from 'react';
import { Col, LoginButton, NavbarLink, Primarybutton, Row, Subtext } from '../general';
import Logo from './Logo';
import { navigate } from 'gatsby'; //import navigate from gatsby
import InformIcon from './icons/informIcon';
import EventIcon from './icons/EventIcon';
import OnboardIcon from './icons/OnboardingIcon';
import TalkIcon from './icons/TalkIcon';
import ColleaguesIcon from './icons/ColleaguesIcon';
import BrandingIcon from './icons/brandinIcon';
import SettingsIcon from './icons/SettingsIcon';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';


const DropdownItem = (props) => {
    return (
        <button onClick={() => navigate(`/features/${props.link}`)} className="text-left" >

            <Row className="gap-4">
                <div className='self-top'>
                    {props.icon}

                </div>
                <Col className="self-center gap-0">
                    <Link to={`/features/${props.link}`} className="no-underline text-black font-bold">{props.title}</Link>
                    <Subtext text={props.subtitle} />
                </Col>
            </Row>
        </button>

    );
}


const Navbar = (props) => {
    const { t, i18n } = useTranslation();

    const [activeBar, setNavbar] = useState([]);
    const [menuActive, setMenu] = useState(false);
    const [dropdownActive, setDropdown] = useState(false);
    const [switchValue, setSwitch] = useState(i18n.language === 'en' ? false : true);
    //navbar scroll changeBackground function
    const changeBackground = () => {
        if (window.scrollY >= 66) {
            setNavbar(true)
            console.log(window.scrollY)

        } else {
            setNavbar(false)
        }
    }

    function onNavClick() {
        var element = document.getElementById("nav-icon2");
        element.classList.toggle("open");
        setMenu(!menuActive)
    }

    useEffect(() => {
        changeBackground()
        // adding the event when scroll change Logo
        window.addEventListener("scroll", changeBackground)
    })

    const navLinkColor = activeBar ? 'text-black' : props.whiteBg ? 'text-black' : '';
    const navLoginButton = activeBar ? 'navbarActiveLogin' : '';
    return (
        <div className='' >
            <div className={menuActive ? 'mobileMenu' : 'mobileMenuDisabled'}>
                <Col className={menuActive ? 'menuCol w-full justify-center' : 'menuColDisabled items-center justify-center'}>
                    <button className='text-left' onClick={() => {
                        setDropdown(!dropdownActive)
                        console.log(dropdownActive)

                    }}>
                        <NavbarLink className='text-black text-2xl font-bold product-link' text={t('nav.productTitle')} isLink={false} />
                    </button>

                    {dropdownActive && <Col>
                        <DropdownItem icon={<InformIcon />} title={t('nav.inform')} subtitle={t('nav.informSub')} link='inform' />
                        <DropdownItem icon={<EventIcon />} title={t('nav.scheduling')} subtitle={t('nav.schedulingSub')} link='scheduling' />
                        <DropdownItem icon={<OnboardIcon />} title={t('nav.onboard')} subtitle={t('nav.onboardSub')} link='onboard' />
                        <DropdownItem icon={<TalkIcon />} title={t('nav.talk')} subtitle={t('nav.talkSub')} link='talk' />
                        <DropdownItem icon={<ColleaguesIcon />} title={t('nav.colleagues')} subtitle={t('nav.colleaguesSub')} link='colleagues' />
                        <DropdownItem icon={<BrandingIcon />} title={t('nav.branding')} subtitle={t('nav.brandingSub')} link='branding' />
                        <DropdownItem icon={<SettingsIcon />} title={t('nav.settings')} subtitle={t('nav.settingsSub')} link='settings' />
                    </Col>}

                    <NavbarLink className='text-black text-2xl font-bold' text={t('nav.learnTitle')} link='https://www.help.relioapp.com' />
                    <NavbarLink className='text-black text-2xl font-bold' text={t('nav.pricingTitle')} link='/pricing' />
                    <NavbarLink className='text-black text-2xl font-bold' text={t('nav.contactTitle')} link='/contact' />
                    <NavbarLink className='text-black text-2xl font-bold' text={t('nav.login')} link='https://www.dashboard.relioapp.com' />
                    <Primarybutton text={t('nav.cta')} className='min-w-full my-5 text-2xl ' />
                    <div className="switch w-fit self-center">
                        <input id="language-toggle" class="check-toggle check-toggle-round-flat" value={switchValue} checked={switchValue} type="checkbox" onChange={(e) => {
                            setSwitch(!switchValue)
                            props.switchFunction(!switchValue)

                        }} />
                        <label for="language-toggle"></label>
                        <span className="on">EN</span>
                        <span className="off">NL</span>
                    </div>
                </Col>
            </div>
            <div className={menuActive ? 'mobileMenuBackdrop' : 'mobileMenuBackdropDisabled'} />
            <div className='fixed h-5 z-50 w-screen'>
                <div className={activeBar ? 'navBackground' : 'navBackgroundDisabled'}>
                    <div className='flex flex-row justify-between p-5 items-center mx-auto container'>
                        <div className='w-40'>
                            <Link to='/'>
                                <Logo />

                            </Link>
                        </div>

                        <div className='block lg:hidden' onClick={onNavClick} id="nav-icon2">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <Row className='hidden lg:flex w-full gap-10 h-min justify-end'>
                            <div className='product-link'>
                                <NavbarLink className={navLinkColor} text={t('nav.productTitle')} />
                                <div className='dropdown-content-product'>

                                    <Row className='items-start gap-7 '>
                                        <Col className="self-start w-64 gap-3">
                                            <DropdownItem icon={<InformIcon />} title={t('nav.inform')} subtitle={t('nav.informSub')} link='inform' />
                                            <DropdownItem icon={<EventIcon />} title={t('nav.scheduling')} subtitle={t('nav.schedulingSub')} link='scheduling' />
                                            <DropdownItem icon={<OnboardIcon />} title={t('nav.onboard')} subtitle={t('nav.onboardSub')} link='onboard' />
                                            <DropdownItem icon={<TalkIcon />} title={t('nav.talk')} subtitle={t('nav.talkSub')} link='talk' />

                                        </Col>
                                        <Col className="self-start w-64 gap-3">
                                            <DropdownItem icon={<ColleaguesIcon />} title={t('nav.colleagues')} subtitle={t('nav.colleaguesSub')} link='colleagues' />
                                            <DropdownItem icon={<BrandingIcon />} title={t('nav.branding')} subtitle={t('nav.brandingSub')} link='branding' />
                                            <DropdownItem icon={<SettingsIcon />} title={t('nav.settings')} subtitle={t('nav.settingsSub')} link='settings' />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <NavbarLink className={navLinkColor} text={t('nav.learnTitle')} link='https://www.help.relioapp.com' />
                            <NavbarLink className={navLinkColor} text={t('nav.pricingTitle')} link='/pricing' />
                            <NavbarLink className={navLinkColor} text={t('nav.contactTitle')} link='/contact' />

                            <LoginButton className={navLoginButton} text={t('nav.login')} />
                            <Primarybutton className="" text={t('nav.cta')} />
                            <div class="switch">
                                <input id="language-toggle" class="check-toggle check-toggle-round-flat" value={switchValue} checked={switchValue} type="checkbox" onChange={(e) => {
                                    setSwitch(!switchValue)
                                    props.switchFunction(!switchValue)

                                }} />
                                <label for="language-toggle"></label>
                                <span className="on">EN</span>
                                <span className="off">NL</span>
                            </div>
                        </Row>
                    </div>
                </div>


            </div>
        </div>)


}

export default Navbar;