import { Link } from 'gatsby-plugin-react-i18next';
import React from 'react';

export const Primarybutton = (props) => {
    return (
        <Link to={props.link ?? 'https://www.dashboard.relioapp.com/register'} className="">
            <button onClick={props.onClick} className={`primaryButton max-w-fit text-lg font-bold ${props.className}`}><div className=' whitespace-nowrap'>{props.text}</div></button>
        </Link>

    )
}

export const LoginButton = (props) => {
    return (
        <Link to={props.link ?? 'https://www.dashboard.relioapp.com/login'} className="">

            <button onClick={props.onClick} className={`loginButton max-w-fit text-lg font-bold whitespace-nowrap ${props.className}`}>{props.text}</button>
        </Link>

    )
}

export const Secondarybutton = (props) => {
    return (
        <Link to={props.link ?? 'https://www.dashboard.relioapp.com/register'} className="">

            <button onClick={props.onClick} className={`secondaryButton max-w-fit text-lg font-bold whitespace-nowrap ${props.className}`}>{props.text}</button>
        </Link>
    )
}

export const FooterLink = (props) => {
    return (

        <Link to={`${props.link}`} className='hover:underline no-underline' >
            <a className={`text-white hover:underline no-underline ${props.className}`} href={props.link}>
                {props.text}
            </a>
        </Link>
    )
}

export const NavbarLink = (props) => {
    if (props.isLink !== false) {
        return (
            <Link to={`${props.link}`} className='hover:underline no-underline' >
                <a className={`text-white text-lg ${props.className}`} >
                    {props.text}
                </a>
            </Link>

        )
    }
    return (

        <a className={`text-white text-lg hover:underline no-underline ${props.className}`} >
            {props.text}
        </a>


    )

}