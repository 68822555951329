import React from 'react';

const EventIcon = () => {
    return (
        <div className='w-10'>
            <svg width="100%" height="100%" viewBox="0 0 267 267">
                <defs>
                    <linearGradient id="linear-gradient" x2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#3376bd" stop-opacity="0.161" />
                        <stop offset="0.148" stop-color="#3e76bc" stop-opacity="0.161" />
                        <stop offset="0.455" stop-color="#d977af" stop-opacity="0.161" />
                        <stop offset="0.545" stop-color="#ec7ba3" stop-opacity="0.161" />
                        <stop offset="0.636" stop-color="#f98196" stop-opacity="0.161" />
                        <stop offset="0.727" stop-color="#ff8b88" stop-opacity="0.161" />
                        <stop offset="0.818" stop-color="#ff977c" stop-opacity="0.212" />
                        <stop offset="0.909" stop-color="#ffa672" stop-opacity="0.212" />
                        <stop offset="1" stop-color="#ffb56b" stop-opacity="0.212" />
                    </linearGradient>
                </defs>
                <g id="Group_14" data-name="Group 14" transform="translate(-11942 -1259)">
                    <circle id="Ellipse_15" className='iconTrans' data-name="Ellipse 15" cx="133.5" cy="133.5" r="133.5" transform="translate(11942 1259)" fill="url(#linear-gradient)" />
                    <path id="Icon_material-event-available" data-name="Icon material-event-available" d="M77.121,55.5l-5.689-5.689L45.239,76,33.86,64.621,28.17,70.31,45.239,87.378ZM90.378,12.235H85.011V1.5H74.276V12.235H31.337V1.5H20.6V12.235H15.235A10.686,10.686,0,0,0,4.554,22.97L4.5,98.113a10.732,10.732,0,0,0,10.735,10.735H90.378a10.766,10.766,0,0,0,10.735-10.735V22.97A10.766,10.766,0,0,0,90.378,12.235Zm0,85.878H15.235V39.072H90.378Z" transform="translate(12022.693 1337.326)" fill="#b477b3" />
                </g>
            </svg>


        </div>
    )
}

export default EventIcon