import React from 'react';


export const H1 = (props) => {

    return (
        <h1 className={props.className}>
            {props.text}
        </h1>
    )
}

export const H2 = (props) => {

    return (
        <h2 className={props.className}>
            {props.text}
        </h2>
    )
}
export const H3 = (props) => {

    return (
        <h3 className={props.className}>
            {props.text}
        </h3>
    )
}

export const H4 = (props) => {

    return (
        <h4 className={props.className}>
            {props.text}
        </h4>
    )
}

export const Paragraph = (props) => {
    return (
        <p className={props.className}>
            {props.text}
        </p>
    )
}

export const Subtext = (props) => {
    return (
        <p className={`subtext ${props.className}`} style={{color: props.color}}>
            {props.text}
        </p>
    )
}

