import React, { Component } from 'react';

class Buoy extends Component {
  
    render() {
        return (<div>
            <svg xmlns="http://www.w3.org/2000/svg" width="45.961" height="45.961" viewBox="0 0 45.961 45.961">
                <path id="Icon_awesome-life-ring" data-name="Icon awesome-life-ring" d="M23.543.562A22.981,22.981,0,1,0,46.524,23.543,22.98,22.98,0,0,0,23.543.562Zm16.1,11.079-5.875,5.875a11.993,11.993,0,0,0-4.193-4.193l5.875-5.875a20.237,20.237,0,0,1,4.193,4.193Zm-16.1,20.8a8.9,8.9,0,1,1,8.9-8.9A8.9,8.9,0,0,1,23.543,32.439ZM11.641,7.448l5.875,5.875a11.993,11.993,0,0,0-4.193,4.193L7.448,11.641a20.235,20.235,0,0,1,4.193-4.193Zm-4.193,28,5.875-5.875a11.993,11.993,0,0,0,4.193,4.193l-5.875,5.875a20.237,20.237,0,0,1-4.193-4.193Zm28,4.193L29.57,33.764a11.993,11.993,0,0,0,4.193-4.193l5.875,5.875A20.238,20.238,0,0,1,35.445,39.638Z" transform="translate(-0.563 -0.563)" fill="#fff" />
            </svg>

        </div>);
    }
}

export default Buoy;