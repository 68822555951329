import React from 'react';

const FooterBg = () => {
  return (
    <div>
      <svg width="100%" height="100%" viewBox="0 0 3957.494 2861.665">
        <defs>
          <linearGradient id="linear-gradientbg" x1="0.166" y1="1.372" x2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#3376bd" />
            <stop offset="0.148" stopColor="#3e76bc" />
            <stop offset="0.455" stopColor="#d977af" />
            <stop offset="0.545" stopColor="#ec7ba3" />
            <stop offset="0.636" stopColor="#f98196" />
            <stop offset="0.727" stopColor="#ff8b88" />
            <stop offset="0.818" stopColor="#ff977c" />
            <stop offset="0.909" stopColor="#ffa672" />
            <stop offset="1" stopColor="#ffb56b" />
          </linearGradient>
        </defs>
        <path id="Path_40" data-name="Path 40" d="M1003.8,6659.756s1002.126-44.465,1449.612,607.611,1191.907,1485.688,223.325,2016.765-2880.553,0-2880.553,0-379.25-85-567.687-721.068S-344.3,7107.542-147.888,6928.733,447.8,6687.507,447.8,6687.507s243.59-36.106,365.184-27.751S1003.8,6659.756,1003.8,6659.756Z" transform="translate(806.919 -6658.501)" fill="url(#linear-gradientbg)" />
      </svg>


    </div>);

}

export default FooterBg;