import React from 'react';

export const Col = (props) => {
    return (
        <div className={`flex flex-col gap-2 ${props.className}` } style={props.style} >
            {props.children}
        </div>
    )
}

export const Row = (props) => {
    return (
        <div className={`flex flex-row gap-2 items-center ${props.className}` } >
            {props.children}
        </div>
    )
}


