import React, { Component } from 'react';

class Shield extends Component {
  
    render() {
        return (<div>
            <svg xmlns="http://www.w3.org/2000/svg" width="44.797" height="54.752" viewBox="0 0 44.797 54.752">
                <path id="Icon_material-security" data-name="Icon material-security" d="M26.9,1.5,4.5,11.455V26.387c0,13.812,9.557,26.729,22.4,29.865C39.74,53.116,49.3,40.2,49.3,26.387V11.455Zm0,27.351H44.319C43,39.1,36.156,48.238,26.9,51.1V28.876H9.477V14.69L26.9,6.95Z" transform="translate(-4.5 -1.5)" fill="#fff" />
            </svg>


        </div>);
    }
}

export default Shield;