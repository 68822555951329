import React from 'react';

const TalkIcon = () => {
    return (
        <div className='w-10'>
            <svg width="100%" height="100%" viewBox="0 0 267 267">
                <defs>
                    <linearGradient id="linear-gradient" x2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#3376bd" stop-opacity="0.212" />
                        <stop offset="0.148" stop-color="#3e76bc" stop-opacity="0.212" />
                        <stop offset="0.455" stop-color="#d977af" stop-opacity="0.212" />
                        <stop offset="0.545" stop-color="#ec7ba3" stop-opacity="0.212" />
                        <stop offset="0.636" stop-color="#f98196" stop-opacity="0.212" />
                        <stop offset="0.727" stop-color="#ff8b88" stop-opacity="0.212" />
                        <stop offset="0.818" stop-color="#ff977c" stop-opacity="0.212" />
                        <stop offset="0.909" stop-color="#ffa672" stop-opacity="0.212" />
                        <stop offset="1" stop-color="#ffb56b" stop-opacity="0.212" />
                    </linearGradient>
                </defs>
                <g id="Group_16" data-name="Group 16" transform="translate(-11942 -1979)">
                    <circle id="Ellipse_13" className='iconTrans' data-name="Ellipse 13" cx="133.5" cy="133.5" r="133.5" transform="translate(11942 1979)" fill="url(#linear-gradient)" />
                    <path id="Icon_material-chat" data-name="Icon material-chat" d="M95.113,3H13.235A10.221,10.221,0,0,0,3.051,13.235L3,105.348l20.47-20.47H95.113a10.265,10.265,0,0,0,10.235-10.235V13.235A10.265,10.265,0,0,0,95.113,3ZM23.47,38.822H84.878V49.057H23.47ZM64.409,64.409H23.47V54.174H64.409Zm20.47-30.7H23.47V23.47H84.878Z" transform="translate(12021.326 2060.826)" fill="#f0888a" />
                </g>
            </svg>


        </div>
    )
}

export default TalkIcon