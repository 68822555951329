import React from 'react';

const InformIcon = () => {
    return (
        <div className='w-10'>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 267 267">
                <defs>
                    <linearGradient id="linear-gradient" x2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#3376bd" stop-opacity="0.212" />
                        <stop offset="0.148" stop-color="#3e76bc" stop-opacity="0.212" />
                        <stop offset="0.455" stop-color="#d977af" stop-opacity="0.212" />
                        <stop offset="0.545" stop-color="#ec7ba3" stop-opacity="0.212" />
                        <stop offset="0.636" stop-color="#f98196" stop-opacity="0.212" />
                        <stop offset="0.727" stop-color="#ff8b88" stop-opacity="0.212" />
                        <stop offset="0.818" stop-color="#ff977c" stop-opacity="0.212" />
                        <stop offset="0.909" stop-color="#ffa672" stop-opacity="0.212" />
                        <stop offset="1" stop-color="#ffb56b" stop-opacity="0.212" />
                    </linearGradient>
                </defs>
                <g id="Group_17" data-name="Group 17" transform="translate(-11942 -899)">
                    <circle id="Ellipse_11" className='iconTrans' data-name="Ellipse 11" cx="133.5" cy="133.5" r="133.5" transform="translate(11942 899)" opacity="0.504" fill="url(#linear-gradient)" />
                    <path id="Icon_awesome-newspaper"  data-name="Icon awesome-newspaper" d="M102.031,4.5H16.266c-2.45,0-4.436,3-4.436,6.709v2.236H4.436c-2.45,0-4.436,3-4.436,6.709V96.193c0,8.646,4.634,15.655,10.351,15.655H97.594c4.9,0,8.872-6.008,8.872-13.418V11.209C106.467,7.5,104.481,4.5,102.031,4.5ZM10.351,98.429c-.817,0-1.479-1-1.479-2.236V26.864H11.83V96.193C11.83,97.428,11.168,98.429,10.351,98.429Zm43.622-4.473h-28.1c-1.225,0-2.218-1.5-2.218-3.355V88.366c0-1.853.993-3.355,2.218-3.355h28.1c1.225,0,2.218,1.5,2.218,3.355V90.6C56.191,92.454,55.2,93.957,53.973,93.957Zm38.446,0h-28.1c-1.225,0-2.218-1.5-2.218-3.355V88.366c0-1.853.993-3.355,2.218-3.355h28.1c1.225,0,2.218,1.5,2.218,3.355V90.6C94.637,92.454,93.644,93.957,92.419,93.957ZM53.973,67.12h-28.1c-1.225,0-2.218-1.5-2.218-3.355V61.529c0-1.853.993-3.355,2.218-3.355h28.1c1.225,0,2.218,1.5,2.218,3.355v2.236C56.191,65.618,55.2,67.12,53.973,67.12Zm38.446,0h-28.1c-1.225,0-2.218-1.5-2.218-3.355V61.529c0-1.853.993-3.355,2.218-3.355h28.1c1.225,0,2.218,1.5,2.218,3.355v2.236C94.637,65.618,93.644,67.12,92.419,67.12Zm0-26.837H25.877c-1.225,0-2.218-1.5-2.218-3.355V25.746c0-1.853.993-3.355,2.218-3.355H92.419c1.225,0,2.218,1.5,2.218,3.355V36.928C94.637,38.781,93.644,40.283,92.419,40.283Z" transform="translate(12022.267 974.326)" fill="#3c76bc" />
                </g>
            </svg>

        </div>
    )
}

export default InformIcon