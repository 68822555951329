import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FooterLink, H2, H4, Paragraph, Primarybutton, Row } from '../general';
import FooterLinkCollection from './FooterLinksCollection';
import Buoy from './svg/Buoy';
import FooterBg from './svg/FooterBg';
import LogoFooter from './svg/LogoFooter';
import Shield from './svg/Shield';





const Footer = () => {
    const { t } = useTranslation();
    return (
        <div className='relative overflow-hidden '>
            <div className='footerBg absolute z-0'>
                <FooterBg />
            </div>
            <Col className='z-20 relative container px-5 mx-auto pt-5'>
                <Col className='subscribeCard lg:flex-row lg:justify-evenly gap-5 lg:gap-10 z-20'>
                    <Row className='flex-row justify-end hidden lg:flex'>
                        <div className='w-52 lg:w-5'>
                            <div className='logoFooter'>
                                <LogoFooter />
                            </div>
                        </div>
                    </Row>
                    <Row className="flex-col lg:flex-row">
                        <div className='lg:flex lg:flex-col lg:px-5'>
                            <H2 className='supportH2' text={t('footer.subscribeTitle')} />
                            <Paragraph className='hidden lg:block' text={t('footer.subscribeSubtext')} />
                        </div>
                        <Paragraph className='lg:hidden' text={t('footer.subscribeSubtext')} />
                        <Primarybutton className='primaryButton lg:max-h-fit lg:items-center lg:self-center w-full' text={t('footer.subscribeCta')} link='/contact'  />
                    </Row>
                    <Row className='justify-end lg:hidden'>
                        <div className='w-28'>
                            <LogoFooter />
                        </div>
                    </Row>
                </Col>
                <Col className='relative lg:flex-row lg:justify-between z-20 mt-10 gap-5 mb-5'>
                    <Row className='flex flex-row gap-5 items-center'>
                        <Buoy />
                        <H4 text={t('footer.info1')} />
                    </Row>
                    <Row className='flex flex-row gap-5 items-center'>
                        <Shield />
                        <H4 text={t('footer.info2')} />

                    </Row>
                    <Row className='flex flex-row gap-5 items-center'>
                        <Buoy />
                        <H4 text={t('footer.info3')} />
                    </Row>
                </Col>


                <FooterLinkCollection />
                <div className='divider' />

            </Col>



        </div>

    );

}

export default Footer;