import React from 'react';

const Spacer = () => {
    return (

        <div className=' h-24'>

        </div>

    )
}
export default Spacer;